/* Typeface: IBM Plex Sans Variable from IBM */
/* Source: Available on GitHub at [https://github.com/IBM/plex/tree/master/IBM-Plex-Sans-Variable] */

@font-face {
  font-family: 'IBM Plex Sans Variable';
  src: url('/public/assets/IBM-Plex-Sans-Var-Roman.ttf') format('ttf'), ;
  src: url('/public/assets/IBM\ Plex\ Sans\ Var-Roman.woff') format('woff'), ;
  src: url('/public/assets/IBM-Plex-Sans-Var-Roman.woff2') format('woff2');
}

body {
  margin: 0;
  font-family: 'IBM Plex Sans Variable', 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-numeric: slashed-zero;
  font-feature-settings: 'salt' on;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}