html,
body,
#root,
.App,
.main {
    height: 100%;
    margin: 0;
}

body {
    background-color: #342b02;
    color: #f2eac4;
}

/* ****************************** */
/* Main container of the homepage */
/* ****************************** */

.main {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: minmax(480px, 760px) minmax(680px, 1fr);
    grid-template-rows: 100%;
    height: 100%;
}


/* *************************** */
/* Profile Picture and its div */
/* *************************** */
.profile-picture-div {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    align-items: center;
    display: flex;
    position: relative;
    height: 100%;
}

.profile-picture-div .profile-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* *********** */
/* Content div */
/* *********** */

.content-div {
    position: relative;
    grid-row: 1 / span 1;
    grid-column: 2 / span 1;
    overflow: overlay;

    /* for inside grid */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: 40px 0px;
    grid-template-areas:
        "logo"
        "content";
    padding: 100px;
}

.content-div::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(180deg, rgb(56, 48, 5) 0%, rgb(31.8, 27.57, 6.45) 100%);
    z-index: -1;
}

.content-div::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background-image: url('/public/assets/noise.png');
    opacity: 0.65;
    z-index: 0;
    background-repeat: repeat;
    background-size: 125px;
    mix-blend-mode: hard-light;
}

.logo {
    grid-area: logo;
    width: fit-content;
    cursor: pointer;
}

.content {
    grid-area: content;
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 6px;
    justify-content: flex-end;
    z-index: 1;
}

/* ******************************* */
/* Chat bubble and its prop styles */
/* ******************************* */


.chat-bubble,
.chat-action {
    box-sizing: border-box;
    align-items: center;
    background: linear-gradient(18.38deg, #352D08 12.47%, #3F3506 87.53%) padding-box,
        linear-gradient(180deg, #52460a 0%, #252003 100%) border-box;
    border: 1px solid transparent;
    box-shadow: 0px 2px 6px 0px rgba(28, 23, 2, 0.31);
    color: hsla(49, 65%, 86%, 1);
    max-width: 480px;
    padding: 10px 20px;

    /* Typography Settings */
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.16px;
}

.chat-bubble {
    border-radius: 8px 8px 8px 0px;
}

.chat-image {
    width: 180px;
    height: 240px;
}

.chat-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    /* chatBubble.Radius-chatImage.Padding */
    border-radius: 4px 4px 4px 0px;
}

.chat-bubble:has(.chat-image) {
    padding: 4px;
}

.chat-intro {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.emphasised-text {
    color: #fdfdfa;
    font-weight: 500;
}

.body-text {
    color: #f2eac4;
    font-weight: 350;
}

.important-text {
    color: #fdfdfa;
}

.chat-bubble a {
    color: #fdfdfa;
}

.pikachu-wave {
    margin: 0 2px;
}

/* ******************************** */
/* Chat actions and its prop styles */
/* ******************************** */

.chat-actions-div {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 6px 6px;
    max-width: 480px;
    position: relative;
}

.chat-action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 6px;
    border-radius: 8px;
    cursor: pointer;
    color: #FEFDFB;
    outline: none;
}

.pointer-context {
    cursor: context-menu;
}

/* Chat Action on mobile for collaboration */
.mobile-action {
    position: fixed;
    bottom: -1px;
    left: 0;
    right: 0;
    padding: 24px;
    background: linear-gradient(180deg, #2f290b, #262106);
    border-top: 1px solid #3F3506;
}

.connect-action-mobile {
    display: flex;
    padding: 10px 20px 11px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    width: 100%;
    background: linear-gradient(18.38deg, #352D08 12.47%, #3F3506 87.53%) padding-box,
        linear-gradient(180deg, #52460a 0%, #252003 100%) border-box;
    border: 1px solid transparent;
    box-shadow: 0px 2px 6px 0px rgba(28, 23, 2, 0.31);
    color: #f2eac4;

    /* Typography Settings */
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.16px;
}

/* ************************* */
/* Dropdown Menu for contact */
/* ************************* */
.DropdownMenuContent,
.DropdownMenuSubContent {
    min-width: 240px;
    background: linear-gradient(18.38deg, #352D08 12.47%, #3F3506 87.53%) padding-box,
        linear-gradient(180deg, #5B4E0B 0%, #211C03 100%) border-box;
    /* background: #FEFDFB; */
    border: 1px solid transparent;
    border-radius: 6px;
    padding: 8px;
    box-shadow: 0px 10px 38px -10px hsl(39.88deg 4.35% 9.02% / 39%), 0px 10px 20px -15px hsl(44.3deg 4.35% 9.02% / 28%), 0px 4px 14px 3px hsla(48, 87%, 6%, 0.678);
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    z-index: 1000;
}

.DropdownMenuContent[data-side='top'],
.DropdownMenuSubContent[data-side='top'] {
    animation-name: slideDownAndFade;
}

.DropdownMenuContent[data-side='right'],
.DropdownMenuSubContent[data-side='right'] {
    animation-name: slideLeftAndFade;
}

.DropdownMenuContent[data-side='bottom'],
.DropdownMenuSubContent[data-side='bottom'] {
    animation-name: slideUpAndFade;
}

.DropdownMenuContent[data-side='left'],
.DropdownMenuSubContent[data-side='left'] {
    animation-name: slideRightAndFade;
}


.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
    font-size: 16px;
    line-height: 150%;
    color: #FEFDFB;
    /* color: #352D08; */
    border-radius: 4px;
    display: flex;
    padding: 8px 12px;
    gap: 6px;
    align-items: center;
    height: 25px;
    position: relative;
    user-select: none;
    outline: none;
    cursor: pointer;
}

.DropdownMenuItem:hover {
    background: linear-gradient(18.38deg, #2b2503 12.47%, #342b02 87.53%);

}

.DropdownMenuSubTrigger[data-state='open'] {
    background: linear-gradient(18.38deg, #2f2806 12.47%, #241e02 87.53%);
    /* background: hsla(49, 65%, 86%, 1); */
}

.DropdownMenuLabel {
    font-size: 14px;
    line-height: 160%;
    color: #f2eac4;
    padding: 0px 8px;
    margin-bottom: 4px;
}

.DropdownMenuSeparator {
    height: 1px;
    background-color: #4c4100;
    margin: 4px;
}

.DropdownMenuItemIndicator {
    position: absolute;
    left: 0;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.DropdownMenuArrow {
    fill: #3d3509;
}

@keyframes slideUpAndFade {
    from {
        opacity: 0;
        transform: translateY(2px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRightAndFade {
    from {
        opacity: 0;
        transform: translateX(-2px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeftAndFade {
    from {
        opacity: 0;
        transform: translateX(2px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}


.DropdownMenuIcon {
    display: flex;
    align-items: center;
    color: #C6A608;
}

.DropdownMenuIcon:nth-child(2) {
    margin-left: auto;
}

/* ************* */
/* Toast message */
/* ************* */

.toast-viewport {
    --viewport-padding: 25px;
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: var(--viewport-padding);
    gap: 10px;
    margin: 0;
    list-style: none;
    z-index: 2147483647;
    outline: none;
}

.toast-root {
    background: #0C0C0B;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 6px;
    border-radius: 80px;
    color: #FEFDFB;
    outline: none;
    box-shadow: 0px 2px 6px 0px rgba(28, 23, 2, 0.31);
    padding: 10px 18px;
}

.toast-root[data-state='open'] {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.toast-root[data-state='closed'] {
    animation: hide 100ms ease-in;
}

.toast-root[data-swipe='move'] {
    transform: translateX(var(--radix-toast-swipe-move-x));
}

.toast-root[data-swipe='cancel'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
}

.toast-root[data-swipe='end'] {
    animation: swipeOut 100ms ease-out;
}

@keyframes hide {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(calc(100% + var(--viewport-padding)));
    }

    to {
        transform: translateX(0);
    }
}

@keyframes swipeOut {
    from {
        transform: translateX(var(--radix-toast-swipe-end-x));
    }

    to {
        transform: translateX(calc(100% + var(--viewport-padding)));
    }
}

.toast-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #FEFDFB;
    font-variant-numeric: slashed-zero;
    font-feature-settings: 'salt' on;
    font-size: 16px;
    font-style: normal;
    font-weight: 360;
    line-height: 22px;
    /* 137.5% */
    letter-spacing: 0.16px;
}

.ToastIcon {
    display: flex;
    align-items: center;
}

.toast-close {
    display: flex;
    align-items: center;
    justify-items: center;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
}

/* ********************************************* */
/* Dialog Modal or BottomSheet on mobile devices */
/* ********************************************* */

.dialog-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    inset: 0;
    z-index: 2;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.dialog-overlay[data-state='open'] {
    animation: fadeIn 140ms ease-out;
}

.dialog-overlay[data-state='closed'] {
    animation: fadeOut 140ms ease-in;
}

.dialog-content {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    background: linear-gradient(180deg, #3d3304 12.47%, #3a3109 87.53%);
    border-radius: 8px 8px 0px 0px;
    box-shadow: hsl(45 22% 7% / 35%) 0px -5px 38px -10px, hsl(45 22% 7% / 20%) 0px -5px 20px -15px, inset 0px 2px 1px #4f4200;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 85vh;
    padding: 25px;
    z-index: 3;
}


.dialog-content:focus {
    outline: none;
}

@keyframes slideIn {
    from {
        transform: translateY(200px) scale(0.96);
    }

    to {
        transform: translateY(0px) scale(1);
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0px) scale(1);
    }

    to {
        transform: translateY(200px) scale(0.98);
    }
}

.dialog-content[data-state='open'] {
    animation: slideIn 160ms ease-out;
}

.dialog-content[data-state='closed'] {
    animation: slideOut 160ms ease-in;
}

.dialog-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.dialog-title {
    margin: 0;
    font-size: 16px;
    color: #f2eac4;
    font-weight: 500;
}

.dialog-description {
    margin: 0;
    font-size: 16px;
    color: #C6A608;
    font-weight: 350;
    line-height: 140%;
}

.dialog-email-content,
.dialog-social-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.dialog-content-subheading {
    color: #C6A608;
    font-size: 12px;
    font-style: normal;
    font-weight: 420;
    line-height: 22px;
    /* 183.333% */
    letter-spacing: 0.16px;
    text-transform: uppercase;
    align-self: stretch;
}

.dialog-line-separator {
    width: 100%;
    height: 1px;
    background-color: #4f4200;
}


.dialog-icon-container {
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: #4F4200;
}

.dialog-icon-text {
    color: #f2eac4;
    font-size: 12px;
    font-style: normal;
    font-weight: 350;
    line-height: 22px;
    /* 183.333% */
    letter-spacing: 0.16px;
}

.dialog-close-button {
    position: absolute;
    top: 24px;
    right: 24px;
    font-family: inherit;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: none;
    background-color: transparent;
    border: 0px transparent;
}


/* ************************************************** */
/* Responsive styles - Maxwidth(480px for main layoyt) */
/* ************************************************** */
@media screen and (max-width: 480px) {
    .main {
        padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    }

    .content-div {
        padding: 24px;
    }

    .content .chat-bubble:nth-child(7) {
        margin-bottom: 100px;
    }
}


/* ************************************************** */
/* Responsive styles - Maxwidth(1060 for main layoyt) */
/* ************************************************** */
@media screen and (max-width: 1160px) {

    /* Show hide items */
    .profile-picture-div {
        display: none;
    }

    .chat-bubble:has(.chat-image) {
        display: block;
    }

    /* layout and spacings */
    .main {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        grid-template-columns: auto;
        grid-template-rows: auto;
    }

    .content-div {
        gap: 120px 0px;
    }

}

/* **************************************** */
/* Responsive styles - Width 1161 and above */
/* **************************************** */

@media screen and (min-width: 1161px) {
    .profile-picture-div {
        display: flex;
    }

    .chat-bubble:has(.chat-image) {
        display: none;
    }
}

/* **************************************** */
/* Responsive styles - Width 1601 and above */
/* **************************************** */

@media screen and (min-width: 1601px) {
    .main {
        grid-template-columns: minmax(45%, 1fr) minmax(55%, 1fr);
    }
}